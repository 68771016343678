// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Datatable
@import "../../public/new/assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.css";

// DataPicker
@import "../../public/admin/vendor/bootstrap-datepicker/bootstrap-datepicker.min.css";

// Custom styles for this template
@import "../../public/new/assets/libs/chartist/dist/chartist.min.css";
@import "../../public/new/assets/extra-libs/c3/c3.min.css";
@import "../../public/new/dist/css/style.min.css";

// Custom styles
@import "../../public/admin/css/custom.css";
