.bg-mediseek-image {
    background: url("../img/doctor-clinic.jpg");
    background-position: center;
    background-size: cover;
    height: 577px;
}

#user_image_preview_container {
    width: 100% !important;
}

.vertical-middle {
    vertical-align: middle !important;
}

.nowrap {
    white-space: nowrap !important;
}

.clinic-badge {
    width: 55px !important;
}

.simple-btn {
    min-width: 100px !important;
}

.wd100 {
    width: 100% !important;
}

.wd33 {
    width: 33.33% !important;
}

.breadcrumb {
    background-color: transparent !important;
}

.fw-b {
    font-weight: bold !important;
}

#videos {
    display: inline-block;
}

#subscriber {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

#publisher {
    position: absolute;
    width: 360px;
    height: 240px;
    z-index: 100;
    top: 0;
    right: 25px;
    border: 3px solid white;
    border-radius: 3px;
}

.welcome.welcome-box .btn {
    margin-top: 20px;
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
    box-sizing: border-box;

    height: 40px;

    padding: 10px 12px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    /* border-color: #fa755a; */
    border-color: #e3342f;
    padding-right: calc(1.6em + 0.75rem);
    background-image: url(data:image/svg+xml,%3csvg xmlns=http://www.w3.org/2000/svg width=12 hei…circle cx=6 cy=8.2 r=.6 fill=%23e3342f stroke=none/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right calc(0.4em + 0.1875rem) center;
    background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

#card-errors {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e3342f;
}

.invite-box {
    margin-bottom: 20px;
    margin-left: 5px;
    padding: 35px 25px 25px;
    background: #fff;
    border: 1px solid #ececec;
    box-sizing: border-box;
    border-radius: 5px;
}

#trix-toolbar-1 {
    width: 100% !important;
    overflow-x: auto !important;
}

.status-msg {
    background-color: #e6e6e6;
    display: inline-block;
    padding: 5px 10px 5px 30px;
    position: relative;
    border-radius: 3px;
    margin-top: 10px;
}

.status-msg p {
    margin: 0;
    font-size: 12px;
}

.status-msg:before {
    content: "";
    height: 8px;
    width: 8px;
    background-color: #eca65e;
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 100%;
}
.status-msg.online:before {
   background-color: #43e400 !important;
}

.sidebar-item a small {
    font-size: 10px;
}

ul#awaitingChats li a small {
    margin: 5px 0 0 5px;
    display: block;
    color: gray;
}

input#slugcopy {
    font-size: 14px;
    height: 40px;
}

 #awaitingChats li:hover :after {
    content: "Connect now";
    position: absolute;
    background: #1ea9fb;
    left: 0;
    top:0;
    bottom:0;
    right: 0;
    text-align: center;
    padding-top: 15px;
    color: white;
    font-size: 14px;
}


@media only screen and (max-width: 768px) {
    #videos {
        width: 100%;
    }
    #subscriber {
        position: relative;
        width: 360px;
        height: 240px;
        z-index: 10;
        margin: 10px auto;
        display: block;
        background-color: gray;
    }

    #publisher {
        position: relative;
        width: 360px;
        height: 360px;
        z-index: 100;
        margin: 10px auto;
        display: block;
        right: 0px;
    }

    .guest-view a.nav-toggler , .guest-view .topbartoggler {
        display: none !important;
    }

    .guest-view a.navbar-brand.d-xs-none {
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    #subscriber {
        position: relative;
        width: 100%;
        height: 300px;
    }

    #publisher {
        position: relative;
        width: 100%;
        height: 300px;
    }


  }

.footer {
    bottom: 0 !important;
}

.table-row-vertical-middle {
    vertical-align: middle !important;
}

.table-row-text-center-vertical-middle {
    text-align: center !important;
    vertical-align: middle !important;
}

.table-row-text-right-vertical-middle {
    text-align: right !important;
    vertical-align: middle !important;
}

.table-row-text-center-vertical-middle-nowrap {
    text-align: center !important;
    vertical-align: middle !important;
    white-space: nowrap !important;
}

